import { useEffect, useRef, useState } from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { FeamFormInput } from "../../common";
import { FeamEntities, IDropdown, IProps } from "../../../models/feam-entities";
import { CwsHeaderSection, CwsGeneralSection, CwsSearchSection, CwsFooterControls, FeamFieldSetWrapper } from "../common";
import { CwsSingleTail, CwsMultiTail, CwsMechanicsAssigned } from "./";
import * as factories from "../../../models/factories";
import { IResultStatus, customerService, customerWorksheetService, maintenanceTypeService, oAuth2TokenForWebhookService, payTypeService, paylocityEmployeeService, resultStatus, userRoleService, webhookService, workTitleService } from "../../../services";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import stationService from "../../../services/station-service";
import { pageTitle, processingStatusType, userRoleType } from "../../../models/constants";
import CwsPersonalExpenses from "./CwsPersonalExpenses";
import { useTokenState } from "../../../RequestInterceptors";
import { usePrint } from "../../../PageLayout";
import { isMobile, isIOS,  } from "react-device-detect";
import { formatDateTimeForApi, getTimeInAMAndPM, padTo2Digits } from "../../../models/feam-utils";
import * as BootStrapIcon from 'react-bootstrap-icons';
import { useFeamAuth } from "../../hooks";
import * as utils from "../../../models/feam-utils";
import moment from "moment";


type PrintStateType = { state: boolean, id: number | undefined, formikHelpers: FormikHelpers<FeamEntities.ID407Data> | undefined };
export default function CustomerWorkSheet(props: IProps.ICustomerWorkSheetProps) {
    const [worksheet, setWorksheet] = useState(factories.getD407Data());
    const { isPrint, setIsPrint } = usePrint();
    const [print, setPrint] = useState<PrintStateType>({ state: false, id: undefined, formikHelpers: undefined });
    const [saveAction, setSaveAction] = useState<{ formProps: FormikProps<FeamEntities.ID407Data> | undefined, action: 'create' | 'update' | 'print' | undefined, status: IResultStatus | undefined }>({ formProps: undefined, action: undefined, status: undefined });
    const [workSheetId, setWorkSheetId] = useState<string | undefined>(undefined);
    const [maintenanceType, setMaintenanceType] = useState<{ maintenanceTypesForDropdown: IDropdown[], maintenanceTypes: FeamEntities.IMaintenanceType[] }>({ maintenanceTypesForDropdown: [], maintenanceTypes: [] });
    const [dropdownData, setDropdownData] = useState<{ payTypes: IDropdown[], workTitleTypes: IDropdown[], customers: IDropdown[], stations: IDropdown[] }>({ payTypes: [], workTitleTypes: [], customers: [], stations: [] });
    const [saving, setSaving] = useState(false);
    const { instance, accounts } = useMsal();
    const [isLoaded, setIsLoaded] = useState(false);
    const feamAuth: { user: FeamEntities.ID407userRole | undefined } = useFeamAuth();
    const [users, setUsers] = useState<FeamEntities.IPaylocityEmployeeForAutoInput[]>([]);
    //not able to update customer work sheet after final approval
    const [lock, setLock] = useState(false);
    const [loginUserDetail, setLoginUserDetail] = useState<{ email: string, displayName: string, stationCode: string, stationId: number | undefined }>({ email: '', displayName: '', stationCode: '', stationId: undefined });
    const { isTokenSet } = useTokenState();
    const isSaveClickedRef = useRef(false);

    useEffect(() => {
        if (isTokenSet && !isLoaded) {
            const fetchData = async () => {
                await extractDataFromAD();
            }
            fetchData();
        }
    }, [isTokenSet])

    useEffect(() => {
        if (print.state) {
            window.scrollTo(0, 0);
            setTimeout(() => {
                printHandler();
            }, 700);
        }
    }, [print.state]);

    useEffect(() => {
        if (isLoaded) {
            const fetchData = async () => {
                let id = getD407IdFromQueryString();
                if (id) {
                    await customerWorksheetById(id);
                    setWorkSheetId(id);
                }
            }
            fetchData();
        }
    }, [isLoaded]);

    return (
        <>
            <LoadingOverlayWrapper className="feam-postion-fixed" active={!isLoaded} text="Loading Content..." spinner>
                <Formik
                    initialValues={worksheet}
                    enableReinitialize={true}
                    onSubmit={async (
                        values: FeamEntities.ID407Data,
                        formikHelpers: FormikHelpers<FeamEntities.ID407Data>
                    ) => {

                        await saveCustomWorksheet(JSON.parse(JSON.stringify(values)), formikHelpers);
                    }}
                >
                    {(formProps: FormikProps<FeamEntities.ID407Data>) => {
                        if (!formProps.values.d407id || formProps.values.d407id === 0) {
                            if (formProps.values.multiTail && formProps.values.tailDetails?.length === 1) {
                                formProps.setFieldValue("tailDetails", factories.getTailDetails(true));
                            } else if (!formProps.values.multiTail && formProps.values.tailDetails?.length !== 1) {
                                formProps.setFieldValue("tailDetails", factories.getTailDetails(false));
                            }
                        }
                        return (
                            <Form>
                                <FeamFieldSetWrapper disabled={formProps.isSubmitting} isPrint={print.state || isPrint}>
                                    <div className={!print.state ? "d-flex d-inline-flex container" : "mt-0 mb-0 pb-0 pt-0"}>
                                        <div className={`customer-worksheet-container customer-container-width ${(print.state || isPrint) ? "mt-0 mb-0 pb-0 pt-0" : ""}   ${(print.state || isPrint) && isMobile && isIOS ? "customer-worksheet-container-ios-height" : "customer-worksheet-container-min-height"}`}>
                                            <div className={`d-flex flex-column customer-worksheet-table-container ${(print.state || isPrint) ? "" : "mt-0 mb-0 feam-padding-5"}`}>
                                                <CwsHeaderSection station={formProps.values.stationCode} d407Id={formProps.values.d407id} />
                                                {!(print.state || isPrint) && <CwsSearchSection formProps={formProps} customerWorksheetById={customerWorksheetById} workSheetId={workSheetId ?? ""} onClear={() => clearHandler(formProps)} resetForm={() => resetData(formProps.values.multiTail)} />}
                                                <FeamFieldSetWrapper disabled={lock && !isPrint} isPrint={print.state || isPrint}>
                                                    <>
                                                        {!(print.state || isPrint) && <div className="d-flex d-inline-flex align-items-center justify-content-start w-100 header-col header-col p-1 fw-bold rounded gap-2 text-secondary mt-3 mb-3 ps-2"><BootStrapIcon.Asterisk className="text-danger" size={10} /> <span className="fst-italic"> Only numeric values are allowed for APU, O1 to O4, H1 to H4, Nitrogen and Oxygen. ETA, ATA, ETD, ATD should be entered in HHMM or HH:MM 24hr format - local time - no additional characters.</span></div>}
                                                        <CwsGeneralSection formProps={formProps} maintenanceTypesForDropdown={maintenanceType.maintenanceTypesForDropdown} maintenanceTypes={maintenanceType.maintenanceTypes} />
                                                        {/*//@ts-ignore */}
                                                        {!formProps.values.multiTail && <CwsSingleTail formProps={formProps} arraySelector="tailDetails" maintenanceTypes={maintenanceType.maintenanceTypes} station={dropdownData.stations.find(x => x.value == formProps.values.stationId)?.text} airlineName={dropdownData.customers.find(x => x.value == formProps.values.customerId)?.text} flightDate={formProps.values.entryDate} />}
                                                        {formProps.values.multiTail && <CwsMultiTail formProps={formProps} arraySelector="tailDetails" maintenanceTypes={maintenanceType.maintenanceTypes} station={dropdownData.stations.find(x => x.value == formProps.values.stationId)?.text ?? ''} airlineName={dropdownData.customers.find(x => x.value == formProps.values.customerId)?.text ?? ''} flightDate={formProps.values.entryDate ?? ''} />}
                                                        {/*//@ts-ignore */}
                                                        {formProps.values.maintenanceTypeId && maintenanceType.maintenanceTypes.find(x => x.maintenanceTypeId.toString() === formProps.values.maintenanceTypeId.toString())?.isJobNumberType && <CwsPersonalExpenses formProps={formProps} />}
                                                        {/*//@ts-ignore */}
                                                        <CwsMechanicsAssigned formProps={formProps} arraySelector="mechanicsAssigneds" isPrinting={(print.state || isPrint) ? true : false} employees={users} maintenanceTypes={maintenanceType.maintenanceTypes} payTypes={dropdownData.payTypes} workTitles={dropdownData.workTitleTypes} />
                                                    </>
                                                </FeamFieldSetWrapper>
                                                <div className="d-flex d-inline-flex justify-content-start align-items-start pt-2 w-100 mt-0">
                                                    <div className="d-flex flex-row mt-0  ps-2 gap-5 w-100">
                                                        <div className="d-flex  flex-column justify-content-start align-items-start">
                                                            <FeamFormInput formProps={formProps} feamSelector="preparedBy" className="customer-worksheet-form-control" readOnly={true} />
                                                            <label className="col-form-label justify-content-start">Prepared By:</label>
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-start align-items-start">
                                                            <FeamFormInput formProps={formProps} feamSelector="updatedBy" className="customer-worksheet-form-control" readOnly={true} />
                                                            <label className="col-form-label justify-content-start">Updated By:</label>
                                                        </div>
                                                        <div className="d-flex  flex-column justify-content-start align-items-start">
                                                            <FeamFormInput formProps={formProps} feamSelector="approvedBy" className="customer-worksheet-form-control" readOnly={true} />
                                                            <label className="col-form-label">Approved By:</label>
                                                        </div>
                                                        <div className="d-flex  flex-column justify-content-start align-items-start">
                                                            <FeamFormInput formProps={formProps} feamSelector="recordedBy" className="customer-worksheet-form-control" readOnly={lock} />
                                                            <label className="col-form-label">Recorded By:</label>
                                                        </div>
                                                        <div className="d-flex  flex-column justify-content-start align-items-start">
                                                            <FeamFormInput type="date" formProps={formProps} feamSelector="dateCompleted" className="customer-worksheet-form-control" readOnly={lock} />
                                                            <label className="col-form-label">Date:</label>
                                                        </div>
                                                        {(utils.isSuperAdmin(feamAuth.user) || ((utils.isStationAdmin(feamAuth.user) || utils.isStationApprover(feamAuth.user)) && feamAuth.user?.stationIds?.includes(formProps.values.stationId ?? 0))) && <div className="d-flex  flex-column justify-content-start align-items-start">
                                                            <input name="isApproved" className="form-check-input mb-1" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={formProps.values.isApproved ?? false} onChange={(e) => { approveHandler(e, formProps); }} disabled={lock} />
                                                            <label className="col-form-label" htmlFor="flexSwitchCheckDefault"> Approved: </label>
                                                        </div>
                                                        }
                                                        {!(print.state || isPrint) && <div className="d-flex  flex-column justify-content-start align-items-start"><div className="d-flex flex-row justify-content-center align-items-start">
                                                            <CwsFooterControls d407Id={workSheetId} saving={saving} instance={instance} lock={lock} onSaveClick={() => saveHandler(formProps)} />
                                                        </div></div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FeamFieldSetWrapper>
                            </Form>)
                    }}
                </Formik>
                <Modal isOpen={saveAction.action !== undefined && workSheetId !== undefined}>
                    <ModalBody className="p-3">
                        {`Click Create to Create new record or ${saveAction.formProps?.dirty ? "Update to Update the existing record" : "Print to print the existing record"} or CANCEL to return to the page`}
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary me-1" onClick={() => actionHandler('create')} disabled={saving}>
                            {saving && saveAction.action === "create" ? (<>  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Creating...</>) : "Create New"}
                        </button>
                        {saveAction.formProps?.dirty && <button type="button" className="btn btn-secondary me-1" onClick={() => actionHandler('update')} disabled={saving}>
                            {saving && saveAction.action === "update" ? (<>  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Updating...</>) : "Update"}
                        </button>}
                        {!saveAction.formProps?.dirty && <button type="button" className="btn btn-secondary me-1" onClick={() => actionHandler('print')} disabled={saving}>Print-No Update</button>}
                        <button type="button" className="btn btn-outline-secondary" onClick={() => actionHandler('cancel')} disabled={saving}>Cancel</button >
                    </ModalFooter>
                </Modal>
                <Modal isOpen={saveAction.status !== undefined}>
                    <ModalBody className="p-3">
                        {getResultStatusMessage()}
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            //@ts-ignore
                            if (saveAction.status !== resultStatus.failed) {
                                setPrint(p => ({ ...p, state: true }));
                                setIsPrint(true);
                            }
                            setSaveAction(p => ({ ...p, status: undefined }));

                        }}>OK</button>
                    </ModalFooter>
                </Modal>


            </LoadingOverlayWrapper>
        </>

    );

    function getResultStatusMessage() {
        switch (saveAction.status) {
            //@ts-ignore
            case resultStatus.saved:
                return 'Customer worksheet has saved successfully.';
            //@ts-ignore
            case resultStatus.updated:
                return 'Customer worksheet has updated successfully.';
            //@ts-ignore
            case resultStatus.failed:
                return 'Customer worksheet has failed. Please try again!';
        }
    }

    function clearAfterPrint(e: any) {
        let id = getD407IdFromQueryString();
        if (id) {
            setWorkSheetId(id);
        } else {
            document.title = pageTitle.classicD407Form;
            const d407NewData = resetData(worksheet.multiTail);
            print.formikHelpers?.resetForm({ values: d407NewData });
            setWorksheet(d407NewData);
            setWorkSheetId(undefined);
        }
        setPrint({ state: false, id: undefined, formikHelpers: undefined });
        setIsPrint(false);
    }

    function printHandler() {
        window.addEventListener(isMobile && !isIOS ? "focus" : "afterprint", clearAfterPrint);
        window.print();
        return () => {
            window.removeEventListener(isMobile && !isIOS ? "focus" : "afterprint", clearAfterPrint);
        }
    }

    function getD407IdFromQueryString() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            //@ts-ignore
            get: (searchParams: URLSearchParams, prop: string) => searchParams.get(prop),
        });
        //@ts-ignore
        return params.id;
    }

    function actionHandler(action: 'create' | 'update' | 'cancel' | 'print') {
        if (isSaveClickedRef.current) {
            return;
        }
        if (action === "cancel") {
            alert('You have clicked the cancel button. Your changes have not been saved.');
            setSaveAction({ formProps: undefined, action: undefined, status: undefined });
            setIsPrint(false);
        }
        else if (action === "create") {
            isSaveClickedRef.current = true;
            saveAction.formProps?.setFieldValue("status", null);
            saveAction.formProps?.setFieldValue("approvedBy", '');
            saveAction.formProps?.setFieldValue("D407Id", undefined);
            saveAction.formProps?.submitForm();
            setSaveAction(prev => ({ ...prev, action: action }));
        }
        else if (action === "update") {
            isSaveClickedRef.current = true;
            saveAction.formProps?.submitForm();
            setSaveAction(prev => ({ ...prev, action: action }));
        }
        else if (action === "print") {
            setFileTitle(worksheet);
            setSaveAction({ formProps: undefined, action: undefined, status: undefined });
            setPrint(p => ({ ...p, state: true }));
            setIsPrint(true);
        }
    }

    function saveHandler(formProps: FormikProps<FeamEntities.ID407Data>) {
        if (!isValid(formProps) || isSaveClickedRef.current) {
            return;
        }
        if (!formProps.values.d407id) {
            if (window.confirm('Ready to save your form! Click "OK" to Save or "Cancel" to continue editing.') === false) {
                return;
            }
            isSaveClickedRef.current = true;
            formProps?.submitForm();
            setSaveAction(prev => ({ ...prev, formProps: formProps, action: 'create' }));
        } else {
            setSaveAction({ formProps: formProps, action: 'update', status: undefined });
        }
    }

    function isValid(formProps: FormikProps<FeamEntities.ID407Data>) {
        if (!formProps.values.customerId) {
            alert('The form requires a Customer name for submission');
            return false;
        }
        if (!formProps.values.stationId) {
            alert('The form requires a Station for submission');
            return false;
        }
        if (!formProps.values.maintenanceTypeId) {
            alert('The form requires a MaintenanceType for submission');
            return false;
        }
        if (formProps.values.multiTail && formProps.values.tailDetails?.findIndex(x => x.tail && x.tail !== '') === -1) {
            alert('The form requires at least one Tail for submission');
            return false;
        }
        if (formProps.values.mechanicsAssigneds &&
            formProps.values.mechanicsAssigneds.filter(
                (x) =>
                    (x.mechanicName || x.workTitleCode || x.payCode || x.shiftHours)
                    &&
                    (!x.mechanicName || x.mechanicName.trim().length === 0 || !x.workTitleCode || !x.payCode || !x.shiftHours)).length > 0
        ) {

            const hoursMessage = !formProps.values.isWorkShift
                ? "In, Out"
                : formProps.values.isWorkShift &&
                    (!formProps.values.maintenanceTypeId ||
                        (formProps.values.maintenanceTypeId &&
                            !["AOG", "MRR"].includes(
                                //@ts-ignore
                                maintenanceType.maintenanceTypes.find((x) => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId.toString())?.name
                            )))
                    ? "Start Date, End Date, Shift"
                    : ["AOG", "MRR"].includes(
                        //@ts-ignore
                        maintenanceType.maintenanceTypes.find((x) => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId.toString())?.name
                    )
                        ? "Start Date, End Date"
                        : "Hours";
            alert("The form requires " + hoursMessage + " , Pay and Position along with Mechanic name.");
            return false;
        }
        return true;
    }

    async function saveCustomWorksheet(data: FeamEntities.ID407Data, formikHelpers: FormikHelpers<FeamEntities.ID407Data>) {
        setSaving(true);
        if (!utils.trimObjectProps(data)) {
            setSaving(false);
            setSaveAction(prev => ({ ...prev, status: resultStatus.failed as unknown as IResultStatus }));
            return;
        }
        const currentDate = new Date();
        const time = getTimeInAMAndPM(currentDate);
        if (data.entryDate?.length === 10) {
            data.entryDate = data.entryDate + " " + time;
        }
        if (data.dateCompleted?.length === 10) {
            data.dateCompleted = data.dateCompleted + " " + time;
        }
        data.entryDate = moment(isIOS ? data.entryDate?.replace(/-/g, "/") : data.entryDate).format('MM/DD/YYYY hh:mm A');
        data.dateCompleted = moment(isIOS? data.dateCompleted?.replace(/-/g, "/") : data.dateCompleted).format('MM/DD/YYYY hh:mm A');
        data.systemDateTime = moment(currentDate.toString()).format('MM/DD/YYYY hh:mm A');
        if (!data.maintenanceTypeId || (data.maintenanceTypeId && !maintenanceType.maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === data.maintenanceTypeId?.toString())?.isJobNumberType)) {
            data.personalExpenses = [];
        }
        data.d407id = saveAction.action === "update" ? data.d407id : undefined;
        if (!data.d407id) {
            data.createUtc = currentDate;
            data.createUtcOffset = currentDate.getTimezoneOffset();
            data.preparedBy = data.updatedBy;
        }
        data.updateUtc = currentDate;
        data.updateUtcOffset = currentDate.getTimezoneOffset();
        data.mechanicsAssigneds = data.mechanicsAssigneds?.filter(x =>
            (x.mechanicName && x.mechanicName?.trim() !== '')
        ).map((x, mIndex) => {
            x.d407id = saveAction.action === "update" ? x.d407id : undefined;
            x.mechanicsAssignedId = undefined;

            x.orderNo = mIndex + 1;
            if (data.isWorkShift) {
                x.shiftStartTime = undefined;
                x.shiftEndTime = undefined;
            } else {
                x.shiftStartDate = undefined;
                x.shiftEndDate = undefined;
            }
            if (x.shiftStartDate && x.shiftEndDate) {
                if (x.shiftStartDate.length === 10) {
                    x.shiftStartDate = x.shiftStartDate + " 00:00 AM";
                    x.shiftEndDate = x.shiftEndDate + " 00:00 AM";
                }
                x.shiftStartDate = formatDateTimeForApi(new Date(x.shiftStartDate ?? ''));
                x.shiftEndDate = formatDateTimeForApi(new Date(x.shiftEndDate ?? ''));

            }
            return x;
        });
        if (data.multiTail) {
            if (data.tailDetails) {
                data.tailDetails = data.tailDetails?.filter(x =>
                    x.tail && x.tail?.trim() !== ''
                ).map((x, tIndex) => {
                    x.tailDetailId = undefined;
                    x.orderNo = tIndex + 1;
                    x.d407id = saveAction.action === "update" ? x.d407id : undefined;
                    x.workDetails = x.workDetails?.filter(f => f.description && f.description?.trim() !== '').map((w, wIndex) => {
                        w.tailDetailId = undefined;
                        w.workDetailId = undefined;
                        w.orderNo = wIndex + 1;
                        return w;
                    })
                    return x;
                });
            }
        } else {
            data.tailDetails?.forEach((x, tIndex) => {
                x.tailDetailId = undefined;
                x.orderNo = tIndex + 1;
                x.d407id = saveAction.action === "update" ? x.d407id : undefined;
                x.workDetails = x.workDetails?.filter(x => x.description && x.description?.trim() !== '').map((w, wIndex) => {
                    w.tailDetailId = undefined;
                    w.workDetailId = undefined;
                    w.orderNo = wIndex + 1;
                    return w;
                })
            });
            if (data.personalExpenses && data.personalExpenses.length > 0 && data.d407id) {
                data.personalExpenses[0].personalExpensesId = undefined;
            }
        }

        data.personalExpenses = data.personalExpenses?.map((x, index) => {
            x.d407id = saveAction.action === "update" ? x.d407id : undefined;
            x.personalExpensesId = undefined;
            return x;
        });
        const result = await customerWorksheetService.saveCustomerWorksheet(data)
        isSaveClickedRef.current = false;

        if (result?.status !== resultStatus.failed) {
            const [updatedData, webHookSave] = await Promise.all([await customerWorksheetById(result.id.toString(), true), await webhookService.invokeD407Save(data)]);
            if (updatedData) {
                //@ts-ignore
                setFileTitle(updatedData);
                setSaving(false);
                setWorksheet(updatedData);
                //@ts-ignore
                setWorkSheetId(updatedData.d407id.toString());
                //@ts-ignore
                setSaveAction({ formProps: undefined, action: undefined, status: result?.status });
                setPrint(prev => ({ ...prev, formikHelpers: formikHelpers }))
            }
        } else {
            setSaving(false);
            //@ts-ignore
            setSaveAction(prev => ({ ...prev, status: result?.status }));
        }

    }

    async function customerWorksheetById(id: string, loadAfterSave: boolean = false) {
        if (!id) {
            setWorksheet(factories.getD407Data());
            setLock(false);
            setWorkSheetId(undefined);
        }
        else {
            const data = await customerWorksheetService.getByIdAsync(+id) ?? factories.getD407Data();
            return await reArrangeD407Data(data, loadAfterSave);
        }
    }

    async function reArrangeD407Data(data: FeamEntities.ID407Data, isReturnData: boolean = false) {
        let stationApprovers;
        if (data && data.stationId && !props.d407Data) {
            stationApprovers = await userRoleService.getStationApproversByStation(data.stationId);
        }
        data.entryDate = (new Date(data.entryDate ?? '')).toString();
        data.systemDateTime = (new Date(data.systemDateTime ?? '')).toString();
        data.dateCompleted = (new Date(data.dateCompleted ?? '')).toString();
        //mechanicsAssigned
        if (!data.mechanicsAssigneds || data.mechanicsAssigneds.length === 0) {
            data.mechanicsAssigneds = [...factories.getmechanicsAssigneds()];
        }
        else if (data.mechanicsAssigneds && data.mechanicsAssigneds.length > 0) {
            const mechanicsAssigneds = factories.getmechanicsAssigneds();
            const startIndex = data.mechanicsAssigneds.length;
            for (let index = startIndex; index < startIndex; index++) {
                if (data.mechanicsAssigneds[index].shiftStartDate && data.mechanicsAssigneds[index].shiftEndDate) {
                    data.mechanicsAssigneds[index].shiftStartDate = (new Date(data.mechanicsAssigneds[index].shiftStartDate ?? '')).toString();
                    data.mechanicsAssigneds[index].shiftEndDate = (new Date(data.mechanicsAssigneds[index].shiftEndDate ?? '')).toString();
                }
            }
            for (let index = startIndex; index < mechanicsAssigneds.length; index++) {
                data.mechanicsAssigneds.push({});
            }
        }

        //Single Tail
        if (!data.multiTail) {
            if (!data.tailDetails || data.tailDetails.length === 0) {
                data.tailDetails = factories.getTailDetails(false);
                //@ts-ignore next-line
            } else if (data.tailDetails[0].workDetails.length < 13) {
                //@ts-ignore next-line
                if (data.tailDetails[0].workDetails.length === 0) {
                    //@ts-ignore next-line
                    data.tailDetails[0].workDetails = factories.getWorkDetailForSingleTail();
                }
                //@ts-ignore next-line
                else if (data.tailDetails[0].workDetails.length > 0) {
                    const workDetails = factories.getWorkDetailForSingleTail();
                    //@ts-ignore next-line
                    const startIndex = data.tailDetails[0].workDetails.length;
                    for (let index = startIndex; index < workDetails.length; index++) {
                        data.tailDetails[0].workDetails?.push({});
                    }
                }
            }

        } else {

            //multi tail
            if (!data.tailDetails || data.tailDetails.length === 0) {
                data.tailDetails = factories.getTailDetails(true);
            } else if (data.tailDetails) {
                const numberOfMultiTailPush = 10 - data.tailDetails.length;
                for (let index = 0; index < numberOfMultiTailPush; index++) {
                    data.tailDetails.push(factories.getTailDetail(true));
                }
                for (let index = 0; index < data.tailDetails.length; index++) {
                    if (!data.tailDetails[index].workDetails || data.tailDetails[index].workDetails?.length === 0) {
                        data.tailDetails[index].workDetails = factories.getWorkDetailForMultiTail();
                    }
                }
            }
        }
        if (data.maintenanceTypeId && maintenanceType.maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === data.maintenanceTypeId?.toString())?.isJobNumberType && (!data.personalExpenses || data.personalExpenses.length === 0)) {
            data.personalExpenses = [{}];
        }

        if (!props.d407Data) {
            data.username = loginUserDetail.email;
            data.updatedBy = loginUserDetail.displayName;
            data.displayName = loginUserDetail.displayName;
        }
        if (!isReturnData) {
            setWorksheet(data);
            setLock(false);
            if (data.d407id) {
                //@ts-ignore
                setWorkSheetId(data.d407id);
            }
            if (!isPrint && stationApprovers && data.approvedBy && (!feamAuth.user || (feamAuth.user && (!feamAuth.user.roleTypeIds || feamAuth.user.roleTypeIds.length === 0 || feamAuth.user.roleTypeIds.includes(userRoleType.billingApprover))))) {
                if ((!feamAuth.user || feamAuth.user.stationIds?.includes(data.stationId ?? 0)) && stationApprovers.find(x => x.fullName?.toLocaleLowerCase().trim() === data.approvedBy?.toLocaleLowerCase().trim())) {
                    setLock(true);
                }
            }
            if (data.status === processingStatusType.markedforBilling && (!feamAuth.user || (feamAuth.user && (!feamAuth.user.roleTypeIds || feamAuth.user.roleTypeIds.length === 0 || !feamAuth.user.roleTypeIds.includes(userRoleType.supperAdmin))))) {
                setLock(true);
            }
        }
        else {
            return data;
        }
    }

    function clearHandler(formProps: FormikProps<FeamEntities.ID407Data>) {
        let id = getD407IdFromQueryString();
        if (id) {
            let url = window.location.protocol + '//' + window.location.host;
            window.open(url, "_self");
            return;
        }
        const d407Data = resetData(formProps.values.multiTail);
        formProps.resetForm({ values: d407Data });
        setWorkSheetId(undefined);
        setLock(false);
    }

    function resetData(multiTail?: boolean) {
        const d407Data = factories.getD407Data(multiTail ?? false);
        d407Data.username = loginUserDetail.email;
        d407Data.preparedBy = loginUserDetail.displayName;
        d407Data.updatedBy = loginUserDetail.displayName;
        d407Data.displayName = loginUserDetail.displayName;
        d407Data.stationId = loginUserDetail.stationId;
        d407Data.stationCode = loginUserDetail.stationCode;
        return d407Data;
    }

    async function extractDataFromAD() {
        if (!accounts[0]) {
            return;
        }
        const response = await Promise.all([paylocityEmployeeService.getAll(), stationService.getAll(), maintenanceTypeService.getAll(), payTypeService.getAll(), workTitleService.getAll(), customerService.getAll()]);
        const employees = response[0];
        const stations = response[1];
        const maintenanceTypes = response[2];
        const payTypes = response[3];
        const workTitleTypes = response[4];
        const employee = employees.find(x => x.workEmail?.trim().toLocaleLowerCase() === accounts[0].username.trim().toLocaleLowerCase());
        const stationId = (employee && stations.find(x => x.text === employee.location)) ? stations.find(x => x.text === employee.location)?.value : 120;//'MIA';
        const stationCode = stations.find(x => x.value === stationId?.toString())?.text ?? 'MIA';
        const paylocityEmployees: FeamEntities.IPaylocityEmployeeForAutoInput[] = employees.map((emp: FeamEntities.IPaylocityEmployee) => {
            return { ...emp, value: emp.employeeCode, text: getText(emp) };
        })
        const maintenanceTypesForDropdown = maintenanceTypes.map<IDropdown>(
            (d: FeamEntities.IMaintenanceType): IDropdown => {
                return { text: d.name, value: d.maintenanceTypeId?.toString() };
            }
        );
        if (!props.d407Data) {
            setWorksheet(p => ({
                ...p,
                username: accounts[0].username,
                preparedBy: accounts[0].name ?? "",
                updatedBy: accounts[0].name ?? "",
                displayName: accounts[0].name ?? "",
                //@ts-ignore
                stationId: +stationId,
                stationCode: stationCode ?? "MIA"
            }));

        } else {
            const data = await reArrangeD407Data(props.d407Data, true);
            //@ts-ignore
            setWorksheet(data);
        }
        setUsers(paylocityEmployees)
        setLoginUserDetail({
            email: accounts[0].username, displayName: accounts[0].name ?? "", stationCode: stationCode,
            //@ts-ignore
            stationId: +stationId
        });

        setMaintenanceType({ maintenanceTypesForDropdown: [...maintenanceTypesForDropdown], maintenanceTypes: [...maintenanceTypes] });
        setDropdownData({ payTypes: payTypes, workTitleTypes: workTitleTypes, customers: response[5], stations: stations });
        setIsLoaded(true);


    }
    function getText(item: FeamEntities.IPaylocityEmployee) {
        return ((item.terminationDate ? "F-" + item.employeeCode : item.employeeCode) + "-" + item.firstName + " " + (item.lastName ?? "")).trim();
    }

    function setFileTitle(updatedData: FeamEntities.ID407Data) {
        //@ts-ignore
        let date = new Date(updatedData.entryDate);
        if (date.toString().includes('GMT') || date.toString().includes('T')) {
            //@ts-ignore
            date = [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("");
        }
        //@ts-ignore
        document.title = `${updatedData.aogOrOssNumber ? updatedData.aogOrOssNumber + "-" : ""}${updatedData.stationCode}-${dropdownData.customers.find(x => x.value == updatedData.customerId)?.text ?? ""}-${maintenanceType.maintenanceTypesForDropdown.find(x => x.value == updatedData.maintenanceTypeId)?.text ?? ""}-${date}-${updatedData.d407id}`;
    }

    function approveHandler(
        e: React.ChangeEvent<HTMLInputElement>,
        formProps: FormikProps<FeamEntities.ID407Data>
    ) {
        if (e.target.checked) {
            formProps.setFieldValue("status", processingStatusType.approved);
            formProps.setFieldValue("approvedBy", accounts[0].name ?? "");
        } else {
            formProps.setFieldValue("status", null);
            formProps.setFieldValue("approvedBy", "");
        }
        formProps.handleChange(e);
    }
}