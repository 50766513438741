import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageLayout from './PageLayout';
import RequestInterceptors from './RequestInterceptors';
import { AdvancedSearchPage, BillingProcessingStatusPage, BillingReviewPage, CustomerWorkSheetPage, DynamicCustomerWorkSheetPage, FeamHelpPage, ReportPage, TimeAndLaborPage, UserManagementPage } from './components/pages';
import { useEffect } from 'react';
import CustomerDataSyncPage from './components/pages/CustomerDataSyncPage';

if (process.env.NODE_ENV === 'production') {
    console.log = function () { };
    console.warn = function () { };
    console.info = function () { };
    console.error = function () { };
    console.debug = function () { };
}
function App() {
    useEffect(() => {
        //Disable Ctrl+P
        document.addEventListener("keydown", function (event) {
            if (event.ctrlKey && event.key === "p") {
                event.preventDefault();
            }
        });
    }, [])
    return (
        <PageLayout>
            <RequestInterceptors>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<CustomerWorkSheetPage />} />
                        <Route path="/dynamic" element={<DynamicCustomerWorkSheetPage />} />
                        <Route path="/search" element={<AdvancedSearchPage />} />
                        <Route path="/reports" element={<ReportPage />} />
                        <Route path="/billing" element={<BillingReviewPage />} />
                        <Route path="/help" element={<FeamHelpPage />} />
                        <Route path="/admin/status" element={<BillingProcessingStatusPage />} />
                        <Route path="/admin/users" element={<UserManagementPage />} />
                        <Route path="/admin/timeandlabor" element={<TimeAndLaborPage />} />
                        <Route path="/admin/customersync" element={<CustomerDataSyncPage />} />
                    </Routes>
                </BrowserRouter>
            </RequestInterceptors>
        </PageLayout>
    )
}

export default App;
